<script >
import Icons from "./Icons.vue";

export default {
  name: "AnalyticCountCard",
  components: {Icons},
  props:{
    color:{
      type: [String],
      default: "#004AAD",
    },
    title:{
      type: [String],
      default: "Users",
    },
    count:{
      type: [String],
      default: "0",
    },
  },
  data(){
    return{

    }
  }
}
</script>

<template>
  <div class="total-unit">
    <div class="tw-flex tw-items-center">
      <icons name="cubes" :color="color" class="mr-2" />
      <span class="darker-text ml-1">{{title}}</span>
      <v-spacer></v-spacer>
      <div class="tw-flex">
        <icons name="light-arrow" class="ml-1" style="margin-top:2px" />
      </div>
    </div>
    <p class="total-unit-figure mt-3">{{count}}</p>
    <div class="tw-flex tw-justify-end">
      <icons name="temp-graph" :color="color" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.total-unit {
  width: 280px;
  height: 140px;
  border-radius: 10px;
  padding: 22px;
  background: #feffff;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.03);

  @media (max-width: 620px) {
    width: 100%;
  }
}
.darker-text,
.lighter-text {
  color: #263238;
  font-family: Inter,sans-serif;
  font-size: 12px;
  font-style: normal;
  text-transform: capitalize;
  font-weight: 400;
  line-height: normal;
}
.total-unit-figure {
  color: #4f4f4f;
  font-family: Inter,sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
</style>