<template>
<div class="tw-flex tw-flex-col tw-p-5" style="border-radius: 10px; background: #FDFFFC;" :style="{width: width}">
  <div class="tw-flex tw-w-full" >
    <apexchart ref="revenue" :type="chartType" class="apex-style" :style="{color:companyColor?companyColor: '#19A2C7'}" :options="chartOptions" :series="series"  height="430" />
  </div>
</div>
</template>

<script>
// import CustomSelect from "./CustomSelect";
import { getLastTwelveMonthBookingStat} from "@/services/api/APIService";
import dayjs from "dayjs";
export default {
  name: "BarChart",
  components: {},
  props:{
    seriesData:[],
    xAxisData:[],
    width : [String],
    companyColor :{
      type : [String],
      default: "#004AAD"
    },
    title : [String],
    chartType:{
      type: [String],
      default: 'bar'
    }

  },
  data(){
    return {
      selectedFilter: "",
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          },
          height: '100%',
          fontFamily : 'Inter, serif'
        },
        noData: {
          text: 'Loading...'
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 3,
            columnWidth: '20%',
            barHeight: '70%',
            distributed: false,
          }
          },
        width: '2x',
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          lineCap: 'round',
          width: '1'
        },
        xaxis: {
          type: 'daily',
          categories: ["Jan","Feb","March","April","May","June","July"]
        },
        legend:{
          itemMargin:{
            horizontal:1
          }
        },
        grid:{
          borderColor: 'rgba(79, 79, 79, 0.07)'
        },
        colors: [this.companyColor],
        fill:{
          colors:[this.companyColor],
          opacity:1
        },
        yaxis: {
          labels: {
            formatter: function(val) {
              return val.toFixed(0)
            }
          },
        }
      },
      series: [{
        name: 'Bookings',
        data: [10,40,100,5,25,200,1000]
      }],
    }
  },
  methods:{
    handleSelectedFilter(filter) {
      this.selectedFilter = filter
    },
      sortObjectByDateKey(obj) {
          const arr = Object.entries(obj);
          arr.sort((a, b) => new Date(a[0]) - new Date(b[0]));
          return Object.fromEntries(arr);
      },
    async getRevenuePerDay(){
      let xaxis = []
      let yaxis = []
      await  getLastTwelveMonthBookingStat().then(res =>{
        for(const [key,value] of Object.entries(this.sortObjectByDateKey(res.data))){
          let theKey = dayjs(key).format("MMM, YYYY")
          xaxis.push(theKey)
          yaxis.push(value)
        }
        if (yaxis.length >12){
          this.series[0].data = yaxis.slice(-12)
        }else {
          this.series[0].data = yaxis
        }
        if (xaxis.length > 12){
          this.chartOptions={...this.chartOptions,...{
              xaxis: {
                categories: xaxis.slice(-12)
              }
            }}
        }
        else {
          this.chartOptions={...this.chartOptions,...{
              xaxis: {
                categories: xaxis
              }
            }}
        }

        this.updateSeriesLine()
      }).catch(err=> {
        console.log(err.response);
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false,
        });
      })
    },
    updateSeriesLine() {
      this.$refs.revenue.updateSeries([{
        data: this.series[0].data,
      }], false, true);

    },
  },
  async mounted() {
      await this.getRevenuePerDay()
  }
}
</script>

<style scoped lang="scss">
.title{
  font-family: Inter,serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 19px;
}

.apex-style{
  width: 100% !important;
  height: max-content !important;
  //color: #19A2C7!important;
}
</style>