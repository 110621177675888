<template>
  <div class="tw-flex tw-justify-center">
    <loader v-if="loading" :loading="loading"></loader>
    <div class="sculptor" v-else>
      <div class="top-section tw-flex tw-items-center">
        <p>Products Analytics</p>
      </div>

      <div class="tw-flex card-sculptors">
        <analytic-count-card color="#1058bb" title="Users" :count="formatNumber(generalStat.totalApplicationUser)"></analytic-count-card>
        <analytic-count-card color="#B51ECD" title="Intercity Service Providers" :count="formatNumber(generalStat.totalTransportCompany)"></analytic-count-card>
        <analytic-count-card color="#804CC6" title="Intercity Trips" :count="formatNumber(generalStat.totalIntercityTrips)"></analytic-count-card>
        <analytic-count-card color="#1088be" title="Intercity Bookings" :count="formatNumber(generalStat.totalInterCityBookings)"></analytic-count-card>
        <analytic-count-card color="#119ebf" title="Total Booked Seats" :count="formatNumber(generalStat.totalBookedSeats)"></analytic-count-card>
        <analytic-count-card color="#1002be" title="Gross Merchandises Value (₦)" :count="formatNumber(generalStat.totalIntercitySales)"></analytic-count-card>
        <analytic-count-card color="#1492ff" title="Npod Users" :count="formatNumber(generalStat.totalRegisteredNpodUsers)"></analytic-count-card>
        <analytic-count-card color="#1066bf" title="Mobile Agents" :count="formatNumber(generalStat.totalMobileAgents)"></analytic-count-card>
        <analytic-count-card color="#BD0200" title="Developers" :count="formatNumber(generalStat.totalDevelopers)"></analytic-count-card>
        <analytic-count-card color="#1892bf" title="Insurance Service Provider" :count="formatNumber(generalStat.totalInsuranceProviders)"></analytic-count-card>
      </div>
      <div class="company-dashboard lg:tw-mt-10 tw-mt-5 tw-p-5">
        <div class="tw-flex tw-justify-between tw-w-full tw-items-center tw-flex-wrap">
          <div class="mr-2 tw-mt-5 lg:tw-mt-0 laptop-view">
            <v-btn text class="header" @click="selectedFilter = 'all'"
              :color="selectedFilter === 'all' ? '#263238' : '#939393'">
              <icons :color="selectedFilter === 'all' ? '#004aad' : '#939393'" class="mr-2" name="cubes" />
              Last Twelve Month Bookings
            </v-btn>
          </div>
          <div class="mobile-view">
            <v-select :items="items" item-text="text" hide-details class="darker-text tf-styles" item-value="value" flat
              solo v-model="selectedFilter">
              <template #append>
                <icons name="select-dropdown" />
              </template>
            </v-select>
          </div>
          <div class="mr-2 search-div">

          </div>
        </div>
        <bar-chart width="100%"/>
      </div>
    </div>
    <modal :dialog="showModal" :title="modalTitle"
           :description="modalDesc" icon="oops"
           @close="handleModal" @verifyTransportCompany="chooseCategory"
           :loading="modalLoading"/>
  </div>
</template>

<script>
  import Icons from '../../components/reuseables/Icons.vue';
  import {
    getGeneralStat
  } from '../../services/api/APIService';
  import dayjs from "dayjs";
  import Modal from "@/components/reuseables/Modal.vue";
  import AnalyticCountCard from "../../components/reuseables/AnalyticCountCard.vue";
  import BarChart from "../../components/reuseables/BarChart.vue";
  import Loader from "../../components/reuseables/Loader.vue";
  export default {
    name: "ProductAnalyticsDashboard",
    components: {
      Loader,
      BarChart,
      AnalyticCountCard,
      Modal,
      Icons
    },
    data() {
      return {
        showModal: false,
        modalTitle: "",
        modalDesc: "",
        modalLoading: false,
        color: "#004AAD",
        search: "",
        productLog: [],
        items: [{
            text: "All Products",
            value: "all"
          },
          {
            text: "All Users",
            value: "users"
          },
          {
            text: "All Bookings",
            value: "bookings"
          },
        ],
        payment: [],
        not_found: "Item will show here",
        productHeaders: [{
            text: "Profile",
            value: "profile",
            sortable: false
          },
          {
            text: "Acronym",
            value: "acronym",
            sortable: false
          },
          {
            text: "Name",
            value: "productName",
            sortable: false
          },
          {
            text: "Users",
            value: "users",
            sortable: false
          },
          {
            text: "Status",
            value: "status",
            sortable: false
          },
          {
            text: "",
            align: "end",
            value: "actions",
            sortable: false
          }
        ],
        userHeaders: [{
            text: "Profile",
            value: "profile",
            sortable: false
          },
          {
            text: "Name",
            value: "productUserName",
            sortable: false
          },
          {
            text: "Category",
            value: "category",
            sortable: false
          },
          {
            text: "Status",
            value: "status",
            sortable: false
          },
          {
            text: "Subscription",
            value: "subscription",
            sortable: true
          },
          {
            text: "Verification",
            value: "verified",
            sortable: false
          },
          {
            text: "Start Date",
            value: "startDate",
            sortable: true
          },
          {
            text: "",
            align: "end",
            value: "actions",
            sortable: false
          }
        ],
        usersLog: [],
        bookingHeaders: [{
            text: "Full name",
            value: "traveller",
            sortable: true
          },
          {
            text: "Company",
            value: "tc",
            sortable: false
          },
          {
            text: "Booking ID",
            value: "bookingId",
            sortable: false
          },
          {
            text: "Departure",
            value: "departure",
            sortable: false
          },
          {
            text: "Arrival",
            value: "arrival",
            sortable: false
          },
          {
            text: "Seats",
            value: "seats",
            sortable: false
          },
          {
            text: "Price",
            value: "tripCost",
            sortable: false
          },
          {
            text: "Trip Date",
            value: "tripDate",
            sortable: true
          },
          {
            text: "Time",
            value: "tripTime",
            sortable: false
          },
          {
            text: "Trip status",
            value: "tripStatus",
            sortable: true
          },

          {
            text: "",
            align: "end",
            value: "actions",
            sortable: false
          }
        ],
        bookingsLog: [],
        loading: false,
        selectedFilter: "all",
        generalStat:{}
      };
    },
    methods: {
      formatNumber(number){
        if(number){
          return number.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
        }
        else {
          return 0
        }
      },
      handleModal(){
        if (this.showModal){
          console.log()
        }
        this.showModal = !this.showModal

      },
      chooseCategory(){

      },
      getFirstLetter(word) {
        if (word) {
          return word.charAt(0)
        }
      },
      async getAllGeneralStat(){
        this.loading = true
       await getGeneralStat().then(res =>{
          this.generalStat = res.data
         this.loading = false
        }).catch((err) => {
         this.loading = false
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          });
        }).finally(()=> this.loading = false)
      },
      handleError(error) {
        this.$displaySnackbar({
          message: error.response.data.details[0] || 'An error occurred',
          success: false
        });
      },
      getTime(date, time) {
        return dayjs(date + time).format("LT");
      },
      getDate(date){
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        if (dayjs(date).toDate().toDateString()=== today.toDateString()) {
          return "Today";
        } else if (dayjs(date).toDate().toDateString() === yesterday.toDateString()) {
          return "Yesterday";
        } else {
          return dayjs(date).toDate().toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" });
        }
      }
    },
    computed: {
      totalUsers() {
        let total = 0
        this.productLog.forEach(product => {
          total += product.users
        })
        return total;
      },
      filteredPaymentLogs() {
        if (this.selectedFilter === "bookings") {
          return this.bookingsLog
        } else if (this.selectedFilter === "users") {
          return this.usersLog
        } else {
          return this.productLog
        }
      }
    },
    async created() {
      await  this.getAllGeneralStat()
    }
  };
</script>

<style lang="scss" scoped>
  .sculptor {
    width: 100%;
  }

  .top-section p {
    color: #263238;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
  }

  .laptop-view {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .mobile-view {
    display: none;

    @media (max-width: 768px) {
      display: flex;
    }
  }

  .add-unit-btn {
    display: flex;
    color: #004aad;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    letter-spacing: 0px;
    padding: 12px;
    border-radius: 10px;
  }

  .add-unit-btn:hover {
    background-color: #c1bfbf4c;
  }


  .categories-box {
    width: 130px;
    height: 150px;
    padding: 10px 15px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #feffff;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.03);

    @media (max-width: 620px) {
      height: fit-content;
      width: 240px;
    }
  }

  .search-div {
    width: 300px;

    .v-input {
      width: 100%;
    }

    @media screen and (max-width: 1264px) {
      width: 100%;
      margin-top: 15px;
    }
  }

  .search-id-div {
    width: 180px;
    margin-left: 15px;

    .v-input {
      width: 100%;
    }

    @media screen and (max-width: 576px) {
      width: 100%;
      margin-top: 10px;
      margin-left: 0;
    }
  }

  .inner-sculptor {
    flex-direction: column;

    @media (max-width: 620px) {
      flex-direction: row;
    }
  }

  .darker-text,
  .lighter-text {
    color: #263238;
    font-family: Inter,sans-serif;
    font-size: 12px;
    font-style: normal;
    text-transform: capitalize;
    font-weight: 400;
    line-height: normal;
  }

  ::v-deep .tf-styles input::placeholder {
    color: #959595;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .lighter-text {
    color: #838383;
  }

  .total-unit-figure {
    color: #4f4f4f;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .small-darker-text {
    font-size: 16px;
  }

  .status-tags {
    font-family: Inter;
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: #BE3321;
    /* Set your desired background color here */
    border-radius: 50%;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: white;

  }

  .v-input {
    width: 200px;
  }

  .company-dashboard {
    display: flex;
    background: #ffffff;
    width: 100%;
    min-height: 50vh;
    border-radius: 10px;
    flex-direction: column;
  }

  .card-sculptors {
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 20px;

    @media (max-width: 620px) {
      flex-direction: column;
    }
  }

  .v-chip.v-size--default {
    width: 85px;
    display: flex;
    justify-content: center;
  }

  .header {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #939393;
    letter-spacing: 0px;
    text-transform: capitalize;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: #7cb0f6;
    /* Set your desired background color here */
    border-radius: 50%;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: white;
  }

  .table {
    width: 100%;
  }

  .v-data-table__wrapper>table {
    margin-top: 55px !important;
  }

  .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th:first-child,
  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td:first-child {
    padding-left: 16px;
  }

  .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th,
  .status {
    color: #939393;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
  .td-style {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0;
    text-align: left;
    text-transform: capitalize;
  }

  .company-logo {
    height: 100px;
    width: 100px;
    border-radius: 10px;
    object-fit: contain;
  }

  .no-image {
    display: flex;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    text-align: center;
  }
</style>